

import { redirect, useLocation, useParams } from "react-router-dom";
import { useNavigate, BrowserRouter, Routes, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Select from "react-select";
import { useForm } from "react-hook-form"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk, faTrash, faPenToSquare, faFileImport, faUpLong } from "@fortawesome/free-solid-svg-icons";
import Confirmacion from "../Componentes/Confirmacion";
import { format, parseISO } from 'date-fns';
import '../Estilos/listaeditar.css';
import Nav from "../Componentes/Nav"
import { formatDate } from "date-fns";
import * as XLSX from "xlsx";

function Listaeditar({ user, fecha, setNombre, setFecha }) {



    let Navigate = useNavigate()


    // const { data, loading } = useFetch("http://localhost/BVC/index.php/Rutas/rutajson/" + user + "/" + fecha,)
    const [elementos, Setelementos] = useState([]);
    const [cumplido, setCumplido] = useState(true)
    const [mostrar, setMostrar] = useState(0);
    const [nfecha, setNfecha] = useState(fecha);

    const [ver, setVer] = useState(false);
    const [isopen, setIsopen] = useState(false);
    const [newelem, setNewelem] = useState({});
    const [data, setData] = useState(null)
    const [usuario, setUsuario] = useState(user)
    const [usuarios, setUsuarios] = useState([])
    const [loading, Setloading] = useState(true)
    const [ruta, SetRuta] = useState(false)
    const [elem, setElem] = useState({});
    const [chk, setChecked] = useState(false)
    const { register, setValue, handleSubmit, formState: { errors } } = useForm();
    const [formattedDate, setFormattedDate] = useState('');


    let x = (true)
    let z = (false)
    let y = (false)
    let w = (false)



    useEffect(() => {

        console.log("elem modificado:", elem)

        //"https://bvcdespacho.000webhostapp.com/BVC/index.php/Rutas/Modificar_json"
        fetch("https://dcocce.host/BVC/index.php/Rutas/Modificar_json", {
            method: "POST",
            body: JSON.stringify(elem)
        })
            .then(res => res.json())
            .then(resp => {

            })
        //"https://bvcdespacho.000webhostapp.com/BVC/index.php/Usuarios/allusuarios/"
        fetch("https://dcocce.host/BVC/index.php/Usuarios/allusuarios/",)

            .then(res => res.json())
            .then(data => {

                setUsuarios(data);
                console.log(usuarios)
                console.log(usuario)
            })

        const parsedDate = parseISO(nfecha);
        const formatted = format(parsedDate, 'dd/MM/yyyy');
        setFormattedDate(formatted);
        Setloading(true)
        fetch("https://dcocce.host/BVC/index.php/Rutas/rutajson/" + usuario + "/" + nfecha,)

            .then(res => res.json())
            .then(datas => {

                Setloading(true)
                // setData(data)



                setData(datas)

                if (datas.length > 0) {

                    SetRuta(true)
                } else {

                    SetRuta(false)

                }


                Setloading(false)
                console.log(datas)
                console.log(datas.length)


            })



    },

        [ver]



    );


    const handleonCheckbox = async (event, { elemento }) => {
        console.log(event.target.checked)

        setElem(elemento)
        console.log(elemento.auto)

        if (event.target.checked) {

            setElem(prevData => ({ ...prevData, auto: "1" }))

            console.log(elem)
        } else {

            setElem(prevData => ({ ...prevData, auto: "0" }))

            console.log(elem)
        }




        setVer(!ver);


    }



    const handleselectchange = (event) => {
        console.log(event.value)

        setValue('usuario', event.value)

    }

    const handleClick = ({ elemento }) => {

        setIsopen(true);
        setVer(!ver);

        setNewelem(elemento)


    }

    const handleClickods = () => {

        return Navigate("/insertaruta")

    }

    let navigate = useNavigate();

    const handleClickrefresh = () => {

         
       
        //  window.location.reload();
         setVer(!ver);
        

    }





    const handleUser = data => {

        setIsopen(false)


        console.log(usuario)
        console.log(data.usuario)

if (data.usuario){


        setUsuario(data.usuario)
        setNombre(data.usuario)
}
        setNfecha(data.fecha)

        setFecha(data.fecha)
        console.log(usuario)
        console.log(nfecha)
        setVer(!ver)

    }


    const Cerrar = () => {

        setIsopen(false)

        setVer(!ver);


    }



    const handleBaja = async ({ newelem }) => {






        fetch("https://dcocce.host/BVC/index.php/Rutas/Borrar_json", {
            method: "POST",
            body: JSON.stringify(newelem)
        })
            .then(res => res.json())
            .then(resp => {





                setIsopen(false)
                setVer(!ver);




            })



    };


    const handleSube = async ({ elemento }) => {



        // fetch("http://localhost/BVC/index.php/Rutas/Subir_json", {

        fetch("https://dcocce.host/BVC/index.php/Rutas/Subir_json", {
            method: "POST",
            body: JSON.stringify(elemento)
        })
            .then(res => res.json())
            .then(resp => {

                console.log(resp)



                setVer(!ver);




            })

        console.log(data);


    };



    ////////


    
    // Función para exportar los datos

  const exportDataToExcel = () => {



// Función para seleccionar columnas

    

function seleccionarColumnas(datos, columnasSeleccionadas) {
    return datos.map(fila => {
      let filaSeleccionada = {};
      columnasSeleccionadas.forEach(columna => {
        filaSeleccionada[columna] = fila[columna];
      });
      return filaSeleccionada;
    });
  }
  
  // Ejemplo de columnas seleccionadas (puede venir de un formulario, checkbox, etc.)
  const columnasSeleccionadas = ['ni', 'nc', 'apellido', 'direccion','observaciones','zona','tecnico','tecnico2','cod_resol','descripcion','resolucion',];
  
  // Seleccionar las columnas que se desean exportar
  const datosSeleccionados = seleccionarColumnas(data, columnasSeleccionadas);






    // Crear una hoja de trabajo
    const ws = XLSX.utils.json_to_sheet(datosSeleccionados);
    
    // Crear un libro de trabajo
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Datos");

    // Exportar el archivo Excel
    XLSX.writeFile(wb, "exportado.xlsx");
  };

    

    ////////




    if (loading) {
        return (

            <h3>loading .. .. ..</h3>
        )
    } else {



        return (




            <div >

                <Nav />


                <Navbar className="bg-body-tertiary justify-content-end">

                    
                    <form onSubmit={handleSubmit(handleUser)}>



                        <Row>
                        <Col >
                                <br />
                                <div className="d-grid gap-2">
                                    <Button variant="primary"   style={{ backgroundColor: '#008b8b', borderColor: '#008b8b' }} onClick={handleClickods} >Nueva&nbsp;Ruta </Button>
                                </div>

                            </Col>

                            <Col >
                                <br />
                                <div className="d-grid gap-2">
                                    <Button variant="primary"   style={{ backgroundColor: '#008b8b', borderColor: '#008b8b' }} onClick={exportDataToExcel} >Exportar </Button>
                                </div>

                            </Col>
                        <Col >
                                <br />
                                <div className="d-grid gap-2">
                                    <Button variant="primary"   style={{ backgroundColor: '#008b8b', borderColor: '#008b8b' }} onClick={handleClickrefresh} >Refresh </Button>
                                </div>

                            </Col>

                            
                            <Col >
                                <br />

                                <Button type="submit" style={{ backgroundColor: '#008b8b', borderColor: '#008b8b' }}> Buscar&nbsp;Ruta</Button>


                            </Col>

                            <Col >

                                <label>Fecha</label>

                                <p>   <input type="date"
                                    {...register("fecha", { value: nfecha }, { required: true })} />
                                    {errors.fecha && <p> La fecha es obligatoria</p>}

                                </p>

                            </Col>

                            <Col >

                               
                                {/* <p>    <Select
                                    value={usuario}
                                    options={usuarios.map(usuario => ({ value: usuario.usuario, label: usuario.usuario }))}

                                    onChange={handleselectchange}


                                /> </p> */}

        <label>Usuario</label>

        <select id="usuario" {...register("usuario", { required: true })}>
        <option value={data.usuario}>{data.usuario}</option>
        {usuarios.map((usuario) => (
          <option key={usuario.usuario} value={usuario.usuario}>
            {usuario.usuario}
          </option>
        ))}
      </select>
      {errors.option && <p style={{ color: "red" }}>Elija un tecnico</p>}

                            </Col>

                           

                        </Row>
                    </form>



                </Navbar>




                <center><h3>Ruta de reclamos:  Usuario: {user} Fecha: {formattedDate}</h3></center>

                <div className='inicio'>



                    <Container fluid>

                        <Row>
                            <Col className="col-1 col-sm-1 ">Internet</Col>
                            <Col className="col-1 col-sm-1 ">Cable</Col>
                            <Col className="col-2 col-sm-2 ">Direccion</Col>
                            <Col className="col-2 col-sm-2 d-none d-lg-block">Apellido</Col>
                            <Col className="col-1 col-sm-1 d-none d-lg-block">Zona</Col>
                            <Col className="col-4 col-sm-3 d-none d-lg-block">Falla</Col>
                            <Col className="col-1 col-sm-1" style={{ width: '2%' }}>Cod</Col>



                        </Row>

                    </Container>











                    {data.map(elemento =>




                        <Container fluid className="border border-1">

                            {elemento.cumplido == 1 && ruta ?
                                <Row>


                                    <Col className="col-1 col-sm-1 bg-custom " >{elemento.ni}</Col>
                                    <Col className="col-1 col-sm-1 bg-custom " >{elemento.nc}</Col>
                                    <Col className="col-2 col-sm-2 bg-custom " >{elemento.direccion}</Col>
                                    <Col className="col-sm-2 d-none d-lg-block bg-custom ">{elemento.apellido}</Col>
                                    <Col className="col-sm-1 d-none d-lg-block bg-custom ">{elemento.zona}</Col>
                                    <Col className="col-sm-2 d-none d-lg-block bg-custom ">{elemento.observaciones}</Col>
                                    <Col className="col-sm-1 d-none d-lg-block bg-custom ">{elemento.hora}</Col>
                                    <Col className="col-1 col-sm-1  fw-bold bg-custom" style={{ width: '4%' }}>{elemento.cod_resol}</Col>

                                    {elemento.cumplido == 0 ? <Col className="col-1 col-sm-1 bg-custom" style={{ width: '2%' }}><Link to="/Cerrarods" state={elemento}  > <FontAwesomeIcon icon={faFloppyDisk} /></Link></Col> :



                                        <Col className="col-1 col-sm-1 bg-custom" style={{ width: '2%' }}>  <FontAwesomeIcon icon={faFloppyDisk} style={{ color: 'grey' }}/></Col>}



                                    <Col className="col-1 col-sm-1 bg-custom" style={{ width: '2%' }}> <FontAwesomeIcon icon={faFileImport} style={{ color: 'grey' }} /> </Col>

                                    <Col className="col-1 col-sm-1 bg-custom" style={{ width: '2%' }}><Link to="/Verods" state={elemento}  > <FontAwesomeIcon icon={faPenToSquare} style={{ color: 'white' }} /></Link> </Col>

                                    <Col className="col-1 col-sm-1 bg-custom" style={{ width: '2%' }}> <FontAwesomeIcon icon={faUpLong} style={{ color: 'grey' }} /> </Col>
                                    {elemento.auto == 1 ? w = (true) : w = (false)}
                                    <Col className="col-1 col-sm-1 bg-custom" style={{ width: '2%' }}> <input
                                        type="checkbox"
                                        name="auto"
                                        onChange={(event) => handleonCheckbox(event, { elemento })}
                                        checked={w}

                                    />

                                    </Col>
                                </Row>
                                : null}


                            {elemento.cumplido == 0 && ruta ?
                                <Row>
                                    <Col className="col-1 col-sm-1 " >{elemento.ni}</Col>
                                    <Col className="col-1 col-sm-1 "  >{elemento.nc}</Col>
                                    <Col className="col-2 col-sm-2 " >{elemento.direccion}</Col>
                                    <Col className="col-sm-2 d-none d-lg-block">{elemento.apellido}</Col>
                                    <Col className="col-sm-1 d-none d-lg-block">{elemento.zona}</Col>
                                    <Col className="col-sm-3 d-none d-lg-block">{elemento.observaciones}</Col>
                                    <Col className="col-1 col-sm-1" style={{ width: '4%' }}>{elemento.cod_resol}</Col>




                                    {elemento.cumplido == 0 ? <Col className="col-1 col-sm-1" style={{ width: '2%' }}><Link to="/Modificarods" state={elemento}  > <FontAwesomeIcon icon={faFloppyDisk} /></Link></Col> :
                                        <Col className="col-1 col-sm-1" style={{ width: '2%' }}> <Link to="/Verods" state={elemento}  > <FontAwesomeIcon icon={faFloppyDisk} /></Link></Col>}



                                    <Col className="col-1 col-sm-1" style={{ width: '2%' }}> <Link to="/Insertarods" state={elemento}  ><FontAwesomeIcon icon={faFileImport} /> </Link></Col>

                                    <Col className="col-1 col-sm-1" style={{ width: '2%' }}> <FontAwesomeIcon icon={faTrash} onClick={() => handleClick({ elemento })} /> </Col>

                                    {x ? <Col className="col-1 col-sm-1" style={{ width: '2%' }}> <FontAwesomeIcon icon={faUpLong} /> </Col> : null}
                                    {y ? <Col className="col-1 col-sm-1" style={{ width: '2%' }}> <FontAwesomeIcon icon={faUpLong} /> </Col> : null}
                                    {z ? <Col className="col-1 col-sm-1" style={{ width: '2%' }}> <FontAwesomeIcon icon={faUpLong} onClick={() => handleSube({ elemento })} /> </Col> : null}

                                    {x ? y = (true) : y = (false)}
                                    {x = (false)}
                                    {x === (false) && y === (false) ? z = (true) : null}



                                    {elemento.auto == 1 ? w = (true) : w = (false)}




                                    <Col className="col-1 col-sm-1" style={{ width: '2%' }}> <input
                                        type="checkbox"
                                        name="auto"
                                        onChange={(event) => handleonCheckbox(event, { elemento })}
                                        checked={w}


                                    />

                                    </Col>

                                </Row>
                                : null}






                        </Container>

                    )}







                    {ruta ? null : <p> NO HAY RUTAS CON ESA FECHA Y/O USUARIO </p>}

                    <Confirmacion isopen={isopen} close={Cerrar} borrar={() => handleBaja({ newelem })} />

                    {/* {ver ? <p> Muestro el CLICK </p> : null} */}

                    {/* 
                    <Modal isopen={isopen} close={Cerrar} borrar={() => handleBaja({ newelem })} /> */}



                </div>

            </div>



        )

    }



}

export default Listaeditar;
